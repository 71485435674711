import { template as template_83881850a2bc4f2f894f3577c5183dba } from "@ember/template-compiler";
const FKLabel = template_83881850a2bc4f2f894f3577c5183dba(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
